@import "fonts/DMSans/dm_sans";
@import "fonts/SourceSansPro/source_sans_pro";

:root {
  --color-page-bg: #ffffff;
  --color-neutral: #f8f8f8;
  --color-alto: #dedede;
  --color-purple-heart: #5d3ac4;
  --color-teal: #4fb1ac;
  --color-astronaut: #353b6e;
  --color-east-bay: #4d517e;
  --color-raven: #373d3f;
  --color-pink: rgb(204, 101, 254, 1);
  --color-brand-blue: rgb(8, 35, 202);
  --color-grey: #757575;
  --color-link: #485ddd;
  --color-contrast-light-blue: #f8f9ff;
}

$body-font: "Source Sans Pro", sans-serif;

#root {
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
}

body {
  font-family: $body-font;
  background: var(--color-page-bg) !important;
  color: var(--color-raven) !important;
}

p {
  line-height: 1.8 !important;
}

a,
a:link,
a:visited,
a:hover,
a:active {
  color: var(--color-link);
  text-decoration: underline;
  font-weight: normal;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

.loading-pane {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.left-pane {
  display: flex;
  & .left-navbar {
    overflow: hidden;
    background: var(--color-brand-blue) !important;
    border-radius: 0 !important;
    width: 80px !important;
    transition: all 0.25s ease-in;
    position: relative !important;
    &.open {
      width: 210px !important;
    }
  }
  & .ui.vertical.menu {
    & .item {
      white-space: nowrap !important;
      color: rgba(255, 255, 255, 0.8);
      padding-top: 20px;
      padding-bottom: 20px;
      &:before {
        background: none !important;
      }
    }
  }
}

.navbar-logo {
  max-width: 48px !important;
  text-align: left !important;
}

.navbar-burger {
  position: absolute !important;
  bottom: 0;
  width: 100%;
}

.navbar-icon {
  float: none !important;
  margin: 0 14px !important;
  opacity: 1 !important;
  font-size: 18px !important;
}

.nav-item-text {
  margin-left: 16px;
}

.right-pane {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.global-top-bar {
  position: sticky !important;
  background: var(--color-contrast-light-blue) !important;
  border-bottom: solid 1px #e0e3f5 !important;
  height: 40px;
}

.page-pane {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: 100%;
  overflow-y: hidden;
}

.page-header {
  display: flex;
  align-items: center;
  padding: 30px 40px 30px;
  // position: sticky;
  // top: 40px;
  // z-index: 201;
  background: var(--color-page-bg);
  & .page-header-title {
    display: inline;
    font-size: 1.5em !important;
    flex-grow: 1;
    margin: 0;
  }
}

.page-header-tab {
  & .menu {
    & .item {
      &.active {
        border-color: var(--color-brand-blue) !important;
      }
      &:not(.active) {
        color: var(--color-grey) !important;
      }
    }
  }
}

.page-content-full {
  background: var(--color-contrast-light-blue) !important;
  border-top: solid 1px #e8e8e8;
  padding: 35px 40px 20px;
}

.page-content-wrapper {
  display: flex;
  flex-grow: 1;
  overflow-x: hidden;
  border-top: solid 1px #e8e8e8;
  height: 100%;
  max-height: 100%;
}

.page-content {
  padding: 30px 40px !important;
  flex-grow: 1;
  height: 100%;
}

.page-content-drawer {
  min-width: 280px;
  padding: 26px 30px;
  margin-right: -280px;
  background: var(--color-contrast-light-blue);
  position: relative;
  transition: all 0.1s ease-out;
  border-left: solid 1px #e0e3f5;
  height: 100%;
  min-height: 100%;

  & .drawer-close-btn {
    position: absolute;
    left: 0;
    top: 20px;
  }
  &.open {
    margin-right: 0;
    transition: all 0.25s ease-out;
    & .drawer-close-btn {
      left: -18px;
    }
  }
  & .item-details{
    margin-bottom:10px;
  }
}

thead {
  background-color: grey;
  align-self: flex-start;
  position: sticky !important;
  top: 0px;
  z-index: 200;
}

.ui.secondary.pointing.menu {
  border-bottom: solid 1px #e8e8e8 !important;
  padding: 0 40px;
  margin: 0;
  & .item {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-right: 30px;
  }
}

.ui.tab.active {
  border: none !important;
  padding: 30px 40px !important;
}

.segment {
  &.table-list-heading {
    border: none !important;
    display: flex;
    align-items: center;
    box-shadow: none !important;
    padding: 0;
  }
  & h4 {
    display: inline;
    flex-grow: 1;
    margin: 0;
  }
}

.key-value-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style-type: none;
  padding-inline-start: 0px;
}

.key-value-container > .key-value {
  flex: 70%;
}

.key-value:nth-child(even) {
  text-align: right;
  flex: 30%;
}

.key-value {
  margin-bottom: 10px;
}

.ui.breadcrumb {
  & .active.section {
    font-weight: normal;
  }
}

.ui.toggle.checkbox {
  &.disabled {
    & label:hover::before {
      background: rgba(0, 0, 0, 0.05) !important;
    }
  }
}

.schedule-form-section {
  margin: 10px 0 40px;
  &:last-child {
    margin-bottom: 0;
  }
  &.blue {
    background: var(--color-contrast-light-blue);
    padding: 25px 1.5em 15px;
    border-bottom: solid 1px #e8e8e8 !important;
    margin: 0;
  }
  &.disabled {
    opacity: 0.2;
  }
  & .ui.header {
    margin-bottom: 30px;
    font-size: 15px !important;
  }
}

.formField {
  &.readOnly {
    & .input > input {
      padding-left: 0;
      border: none !important;
    }
  }
}

//positioning the modal so it doesn't "jump" when the modal length changes
.ui.modal.active {
  top: 40px !important;
}

[data-test="project_reservations_list"],
[data-test="projects_list"],
[data-test="machine_access_list"],
[data-test="machine_schedule_list"],
[data-test="project_jobs_list"],
[data-test="users_list"] {
  tr:hover {
    cursor: pointer;
  }
}

div[data-test="orgUserForm"].ui.mini.modal {
  width: 415px;
  div[fieldid="userEmail"] {
    padding-bottom: 20px;
  }
  div[fieldid="userName"] {
    padding: 20px 0;
  }
  #emailSearch {
    position: relative;
    left: 0;
    top: 10px;
  }
}

.scroll_observer {
  height: 10px;
}

.react-datepicker-popper {
  z-index: 201;
}

.schedule-form-pad {
  padding: 1.5rem;
}

.cookie-policy {
  padding-bottom: 80px;
}

a.cookie-policy-link {
  color: #fff;
}

.cookie-button {
  flex: 0 0 auto !important;
  margin: 15px 10px !important;
}

.ui.form {
  & p:first-child {
    margin-top:0;
  }
}
