/* latin-ext */
@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(fonts/DMSans/rP2Hp2ywxg089UriCZ2IHSeH.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(fonts/DMSans/rP2Hp2ywxg089UriCZOIHQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(fonts/SourceSansPro/6xK3dSBYKcSV-LCoeQqfX1RYOo3qNa7lqDY.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(fonts/SourceSansPro/6xK3dSBYKcSV-LCoeQqfX1RYOo3qPK7lqDY.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(fonts/SourceSansPro/6xK3dSBYKcSV-LCoeQqfX1RYOo3qNK7lqDY.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(fonts/SourceSansPro/6xK3dSBYKcSV-LCoeQqfX1RYOo3qO67lqDY.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(fonts/SourceSansPro/6xK3dSBYKcSV-LCoeQqfX1RYOo3qN67lqDY.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(fonts/SourceSansPro/6xK3dSBYKcSV-LCoeQqfX1RYOo3qNq7lqDY.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(fonts/SourceSansPro/6xK3dSBYKcSV-LCoeQqfX1RYOo3qOK7l.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

:root {
  --color-page-bg: #ffffff;
  --color-neutral: #f8f8f8;
  --color-alto: #dedede;
  --color-purple-heart: #5d3ac4;
  --color-teal: #4fb1ac;
  --color-astronaut: #353b6e;
  --color-east-bay: #4d517e;
  --color-raven: #373d3f;
  --color-pink: rgb(204, 101, 254, 1);
  --color-brand-blue: rgb(8, 35, 202);
  --color-grey: #757575;
  --color-link: #485ddd;
  --color-contrast-light-blue: #f8f9ff;
}

#root {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}

body {
  font-family: "Source Sans Pro", sans-serif;
  background: var(--color-page-bg) !important;
  color: var(--color-raven) !important;
}

p {
  line-height: 1.8 !important;
}

a,
a:link,
a:visited,
a:hover,
a:active {
  color: var(--color-link);
  text-decoration: underline;
  font-weight: normal;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    -webkit-animation-duration: 0.01ms !important;
            animation-duration: 0.01ms !important;
    -webkit-animation-iteration-count: 1 !important;
            animation-iteration-count: 1 !important;
    -webkit-transition-duration: 0.01ms !important;
            transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

.loading-pane {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.left-pane {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.left-pane .left-navbar {
  overflow: hidden;
  background: var(--color-brand-blue) !important;
  border-radius: 0 !important;
  width: 80px !important;
  -webkit-transition: all 0.25s ease-in;
  transition: all 0.25s ease-in;
  position: relative !important;
}

.left-pane .left-navbar.open {
  width: 210px !important;
}

.left-pane .ui.vertical.menu .item {
  white-space: nowrap !important;
  color: rgba(255, 255, 255, 0.8);
  padding-top: 20px;
  padding-bottom: 20px;
}

.left-pane .ui.vertical.menu .item:before {
  background: none !important;
}

.navbar-logo {
  max-width: 48px !important;
  text-align: left !important;
}

.navbar-burger {
  position: absolute !important;
  bottom: 0;
  width: 100%;
}

.navbar-icon {
  float: none !important;
  margin: 0 14px !important;
  opacity: 1 !important;
  font-size: 18px !important;
}

.nav-item-text {
  margin-left: 16px;
}

.right-pane {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.global-top-bar {
  position: -webkit-sticky !important;
  position: sticky !important;
  background: var(--color-contrast-light-blue) !important;
  border-bottom: solid 1px #e0e3f5 !important;
  height: 40px;
}

.page-pane {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  max-height: 100%;
  overflow-y: hidden;
}

.page-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 30px 40px 30px;
  background: var(--color-page-bg);
}

.page-header .page-header-title {
  display: inline;
  font-size: 1.5em !important;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin: 0;
}

.page-header-tab .menu .item.active {
  border-color: var(--color-brand-blue) !important;
}

.page-header-tab .menu .item:not(.active) {
  color: var(--color-grey) !important;
}

.page-content-full {
  background: var(--color-contrast-light-blue) !important;
  border-top: solid 1px #e8e8e8;
  padding: 35px 40px 20px;
}

.page-content-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  overflow-x: hidden;
  border-top: solid 1px #e8e8e8;
  height: 100%;
  max-height: 100%;
}

.page-content {
  padding: 30px 40px !important;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  height: 100%;
}

.page-content-drawer {
  min-width: 280px;
  padding: 26px 30px;
  margin-right: -280px;
  background: var(--color-contrast-light-blue);
  position: relative;
  -webkit-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
  border-left: solid 1px #e0e3f5;
  height: 100%;
  min-height: 100%;
}

.page-content-drawer .drawer-close-btn {
  position: absolute;
  left: 0;
  top: 20px;
}

.page-content-drawer.open {
  margin-right: 0;
  -webkit-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
}

.page-content-drawer.open .drawer-close-btn {
  left: -18px;
}

.page-content-drawer .item-details {
  margin-bottom: 10px;
}

thead {
  background-color: grey;
  -ms-flex-item-align: start;
      align-self: flex-start;
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0px;
  z-index: 200;
}

.ui.secondary.pointing.menu {
  border-bottom: solid 1px #e8e8e8 !important;
  padding: 0 40px;
  margin: 0;
}

.ui.secondary.pointing.menu .item {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-right: 30px;
}

.ui.tab.active {
  border: none !important;
  padding: 30px 40px !important;
}

.segment.table-list-heading {
  border: none !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  padding: 0;
}

.segment h4 {
  display: inline;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin: 0;
}

.key-value-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  list-style-type: none;
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
}

.key-value-container > .key-value {
  -webkit-box-flex: 70%;
      -ms-flex: 70%;
          flex: 70%;
}

.key-value:nth-child(even) {
  text-align: right;
  -webkit-box-flex: 30%;
      -ms-flex: 30%;
          flex: 30%;
}

.key-value {
  margin-bottom: 10px;
}

.ui.breadcrumb .active.section {
  font-weight: normal;
}

.ui.toggle.checkbox.disabled label:hover::before {
  background: rgba(0, 0, 0, 0.05) !important;
}

.schedule-form-section {
  margin: 10px 0 40px;
}

.schedule-form-section:last-child {
  margin-bottom: 0;
}

.schedule-form-section.blue {
  background: var(--color-contrast-light-blue);
  padding: 25px 1.5em 15px;
  border-bottom: solid 1px #e8e8e8 !important;
  margin: 0;
}

.schedule-form-section.disabled {
  opacity: 0.2;
}

.schedule-form-section .ui.header {
  margin-bottom: 30px;
  font-size: 15px !important;
}

.formField.readOnly .input > input {
  padding-left: 0;
  border: none !important;
}

.ui.modal.active {
  top: 40px !important;
}

[data-test="project_reservations_list"] tr:hover,
[data-test="projects_list"] tr:hover,
[data-test="machine_access_list"] tr:hover,
[data-test="machine_schedule_list"] tr:hover,
[data-test="project_jobs_list"] tr:hover,
[data-test="users_list"] tr:hover {
  cursor: pointer;
}

div[data-test="orgUserForm"].ui.mini.modal {
  width: 415px;
}

div[data-test="orgUserForm"].ui.mini.modal div[fieldid="userEmail"] {
  padding-bottom: 20px;
}

div[data-test="orgUserForm"].ui.mini.modal div[fieldid="userName"] {
  padding: 20px 0;
}

div[data-test="orgUserForm"].ui.mini.modal #emailSearch {
  position: relative;
  left: 0;
  top: 10px;
}

.scroll_observer {
  height: 10px;
}

.react-datepicker-popper {
  z-index: 201;
}

.schedule-form-pad {
  padding: 1.5rem;
}

.cookie-policy {
  padding-bottom: 80px;
}

a.cookie-policy-link {
  color: #fff;
}

.cookie-button {
  -webkit-box-flex: 0 !important;
      -ms-flex: 0 0 auto !important;
          flex: 0 0 auto !important;
  margin: 15px 10px !important;
}

.ui.form p:first-child {
  margin-top: 0;
}
